<template>
  <div>
    <Alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
    </Alerts>
    <v-card>
      <v-card-title class="text-h5"> {{ $t('Vaccines') }} </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" lg="12">
            <DxDataGrid class="isScrolledByGeneralFilter" :show-borders="true" :data-source="vaccines" key-expr="ID_Service">
              <DxPaging :page-size="5" />
              <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
              <DxColumn data-field="ID_Service" caption=" Id"></DxColumn>
              <DxColumn data-field="NameService" caption="Nombre "></DxColumn>
              <DxColumn data-field="ID_User_Insert" caption="ID de usuario"></DxColumn>
              <template #show-template="{ data }">
                <!--      <div>
                  <v-checkbox
                    v-model="data.data.checkbox"
                    class="pt-0"
                    @click="getSelectedRow(data.data)"
                  ></v-checkbox>
                </div> -->
                <div>
                  <input type="checkbox" id="checkbox" @change="getSelectedRow(data.data)">

                </div>


              </template>
            </DxDataGrid>
            <div class="options">

              <div class="options-container">

                <div class="option">

                  <DxButton text="Contained" styling-mode="contained" style="min-width: 120px;" :height="50"
                    @click="addAll()" type="default">
                    <template #content>
                      <span>
                        <DxLoadIndicator :visible="loadIndicatorVisible" class="button-indicator" />
                        <span class="dx-button-text">Asignar Todos</span>
                      </span>
                    </template>
                  </DxButton>
                </div>
                <div class="option">

                  <DxButton text="Contained" styling-mode="contained" style="min-width: 120px;" :height="50"
                    @click="saveAssign()" type="success">
                    <template #content>
                      <span>
                        <DxLoadIndicator :visible="loadIndicatorVisible" class="button-indicator" />
                        <span class="dx-button-text"  >Asignar seleccionados</span>
                      </span>
                    </template>
                  </DxButton>
                </div>
                &nbsp;

              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="confirmDialog" max-width="650">
      <planVaccineConfirm :name="name" :decision="decision" v-if="confirmDialog" @close="closeDialog"
        @f_call="performfunction($event)"></planVaccineConfirm>
    </v-dialog>
  </div>
</template>

<script>
import { DxButton } from 'devextreme-vue/button';
import { DxDataGrid, DxColumn, DxPaging } from "devextreme-vue/data-grid";
import planVaccineConfirm from "../../../../components/planVaccineConfirm.vue";
import { DxLoadIndicator } from 'devextreme-vue/load-indicator';
import Alerts from "../../../../components/Alerts.vue"
export default {
  name: "vaccineList",
  components: {
    DxDataGrid,
    DxColumn,
    DxButton,
    Alerts,
    DxPaging,
    planVaccineConfirm,
    DxLoadIndicator
  },
  props: ["id", "loginId", "perameters"],
  data() {
    return {
      vaccines: [],
      assignedVaccines: [],
      confirmDialog: false,
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      name: null,
      decision: null,
      loadIndicatorVisible: false,
      data: [],
    };
  },
  mounted() {
    this.getProductVaccines();
    /*  console.log(this.vaccines); */
  },
  watch: {
    perameters() {
      this.getProductVaccines();
    },
  },
  methods: {
    closeAlert() {
      this.alert.show = false;

      /*  if (this.alert.options == "redirectcustomer") {
         this.$store.dispatch("deleteWindowByUuid", { uuid: this.win.uuid });
         this.$store.dispatch("addWindow", {
           name: "customerList",
           component: "CustomersList",
           unique: true,
         }); */
      /*      this.$store.dispatch("reloadData", "customers"); */

    },
    acceptAlert() {
      this.alert.show = false;

      /*  if (this.alert.options == "deletecustomer") {

       } */
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    getProductVaccines() {
      let toSend={
        ID_Business: JSON.parse(localStorage.getItem("user")).businessid,
        ID_Branch: JSON.parse(localStorage.getItem("user")).branch
      }
      this.$API.vaccines.getViewsVaccine(toSend).then((vaccines) => {
        this.vaccines = vaccines;
        this.vaccines.map((ele) => {
          this.perameters.map((sub) => {
            if (sub.ID_Vaccine == ele.ID_Item) ele.checkbox = true;
          });
        });
      });
    },
    getSelectedRow(data) {


      /*   this.confirmDialog = true;
        if (data.checkbox == true) {
          this.decision = "add";
          this.data = data;
        } else {
          this.decision = "remove";
          this.data = data;
        } */
      if (this.assignedVaccines.length >= 1) {
        let isIncluded = this.assignedVaccines.find(e => e.ID_Service === data.ID_Service) !== undefined;
        if (isIncluded) {
          this.assignedVaccines = this.assignedVaccines.filter(v => v.ID_Service != data.ID_Service)
        } else {
          this.assignedVaccines.push(data)
        }
      } else {
        this.assignedVaccines.push(data)
      }
      console.log(data);
    },
    performfunction() {


      let vac = {
        ID_Business : JSON.parse(localStorage.getItem("user")).businessid || "",
        ID_Branch : JSON.parse(localStorage.getItem("user")).branch || "",
        ID_User_Insert: this.loginId,
        id: this.id,
        Vaccine: this.data.NameService,
        ID_Vaccine: this.data.ID_Service,
      };
      this.$API.vaccines.addVaccinePlan(vac).then(
        (response) => {
          /*    this.$toasted.success(response.message); */
          console.log(response.message);
          this.$emit("justRefresh");
          /*   this.closeDialog() */
        },
        (err) => {
          console.log(err);
        }
      );

      /*    this.perameters.map((sub) => {
           if (sub.ID_Vaccine == this.data.ID_Item) {
             this.$API.vaccines
               .deletePlanRow(sub.ID_Perameter)
               .then((response) => {
                 this.$toasted.success(response.message);

               });
           }
         }); */

    },
    saveAssign() {
      this.assignedVaccines.forEach(element => {
        this.data = element
        this.name = element.NameService;
        this.performfunction()
      });
      this.$emit("refreshData");
      this.closeDialog()
      this.assignedVaccines = []
    },
    addAll() {
      this.vaccines.forEach(element => {
        this.data = element
        this.name = element.NameService;
        this.performfunction()
      });
      this.$emit("refreshData");
      this.closeDialog()
    },
    closeDialog() {
      this.confirmDialog = false;
      this.data = [];
      this.getProductVaccines()
    },
  },
};
</script>

<style>
.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  margin-top: 20px;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.options-container .option:last-child {
  float: right;
}

.option {
  min-width: 24%;
  display: inline-block;
  margin: 10px;
}
</style>
