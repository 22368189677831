<template>
  <v-expansion-panel>
      <Alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
      </Alerts>
      <v-expansion-panel-header>
        {{ $t("managevaccineplans")  }}
        <template v-slot:actions>
          <v-icon color="error" @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })">
            mdi-close
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card>
          <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
            <v-tabs-slider color="red"></v-tabs-slider>
            <v-tab href="#tab-1">
              {{ $t("managevaccineplans") }}
              <v-icon>mdi-pencil-circle</v-icon>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
              <v-card flat>
                <v-card-text>
                  <div>
                    <ValidationObserver ref="observer">
                      <v-row>



                        <v-col cols="6" :style="{ marginTop: '5px' }">
                          <div
                            style="padding: 16px;background-color: white;  border-style: solid;border-color: black; border-width: 1px; padding: .5rem; border-radius: 15px; margin: 0.5rem;">
                            <p class="text-h6">{{ $t('Add_Vaccine_Plan') }}</p>
                            <ValidationProvider name="Nombre de plan de vacunas" rules="required" v-slot="{ errors }">
                              <v-text-field outlined dense :error-messages="errors" v-model="form.name"

                                label="Nombre del plan"></v-text-field>

                            </ValidationProvider>

                            <quill-editor label="Comentarios" :content="form.Comment" :options="editorOption"
                              v-model="form.Comment"></quill-editor>
                              <v-btn class="mt-2 btnsave" v-if="form.ID_Vaccine_Plan"
                              @click.prevent.stop="updateMedicationPlan">
                              Actualizar
                            </v-btn>
                            <v-btn v-else class="mt-2 btnsave" large @click.prevent.stop="addVaccine">
                              Agregar
                            </v-btn>
                          </div>
                        </v-col>
                        <v-col cols="6" :style="{ marginTop: '5px' }">
                          <div class="box"
                            style="padding: 16px;background-color: white;  border-style: solid;border-color: black; border-width: 1px; padding: .5rem; border-radius: 15px; margin: 0.5rem;">
                            <p class="pt-5 text-h6 mb-0">
                              {{ $t("VaccinePlansAvailable") }}
                            </p>
                            <DxDataGrid class="isScrolledByGeneralFilter" :data-source="VaccineList" key-expr="ID_Vaccine_Plan"
                              :show-row-lines="showRowLines" :show-borders="showBorders"
                              :row-alternation-enabled="rowAlternationEnabled">
                              <DxScrolling row-rendering-mode="virtual" />
                              <DxPaging :page-size="5" />
                              <DxColumn :width="60" cell-template="show-template" caption=""></DxColumn>
                              <DxColumn data-field="ID_Vaccine_Plan" caption="ID"></DxColumn>
                              <DxColumn data-field="PlanName" caption="Nombre de plan"></DxColumn>
                              <DxColumn :width="60" cell-template="delete-template" caption=""></DxColumn>
                              <template #show-template="{ data }">
                                <a ref="details" href="javascript:void(0)" @click.prevent.stop="
                                  showVaccineDetails(data.data)
                                ">
                                  <v-icon color="primary" small>mdi-eye</v-icon>
                                </a>
                              </template>
                              <template #delete-template="{ data }">
                                <a href="javascript:void(0)" @click.prevent="templateToDelete = data.data;deleteDialog = true;">
                                  <v-icon small>mdi-delete</v-icon>
                                </a>
                              </template>
                            </DxDataGrid>
                          </div>
                        </v-col>

                        <v-col cols="12">

                          <div
                            style="padding: 16px;background-color: white;  border-style: solid;border-color: black; border-width: 1px; padding: .5rem; border-radius: 15px; margin: 0.5rem;">
                            <v-row no-gutters style="color: black;">
                              <v-col cols="2">
                                <span class="text-h6">{{ "Plan seleccionado" }}:</span>
                              </v-col>
                              <v-col cols="6">
                                <span v-if="selectedPerameter.PlanName" class="text-h6">
                                  {{ selectedPerameter.PlanName }}
                                </span>
                                <!--  v-html="selectedPerameter.Comment" -->
                                <span v-else class="text-h6">
                                  {{ "Nose ha seleccionado ningun plan" }}
                                </span>
                              </v-col>
                              <v-col cols="4" style="display: flex; justify-content: flex-end;">
                                <v-btn v-if="selectedPerameter.PlanName" @click.prevent.stop="cleanForm"
                                  class="btnclean">
                                  Limpiar
                                </v-btn>
                              </v-col>
                            </v-row>

                            <v-col lg="12">
                              <v-btn class="float-right btn-add" small @click.prevent.stop="
                                addVaccineDialog = !addVaccineDialog
                              " v-if="planComment">
                                <v-icon color="white"> mdi-plus </v-icon>
                              </v-btn>
                            </v-col>
                            <v-col lg="12" cols="12"  class="mt-2">
                              <DxDataGrid class="isScrolledByGeneralFilter" id="gridContainer" :data-source="details" :allow-column-reordering="true"
                                :show-borders="true" key-expr="ID_Perameter" @row-updated="UpdatePlansRow"
                                @row-removed="DeletePlansRow" v-if="planComment" >
                                <DxPaging :page-size="5" />
                                <DxEditing :allow-updating="true" :allow-deleting="true" :allow-adding="false"
                                  mode="row" />
                                <DxColumn data-field="Vaccine" caption="Vacuna" />
                                <DxColumn data-field="PeriodTime" caption="Periodo de tiempo" data-type="number">
                                </DxColumn>
                                <DxColumn data-field="PeriodUnit" caption="Unidad de periodo">
                                  <DxLookup :data-source="items" display-expr="Name" value-expr="Value" />
                                </DxColumn>
                              </DxDataGrid>
                            </v-col>
                          </div>
                        </v-col>
                      </v-row>
                    </ValidationObserver>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-expansion-panel-content>
      <v-dialog v-model="deleteDialog" max-width="290">
      <delete-template-dialog :data="templateToDelete" :name="templateToDelete.PlanName" v-if="deleteDialog"
        @close="closeDialog" @f_call="deleteVaccine($event)"></delete-template-dialog>
    </v-dialog>
    <v-dialog v-model="addVaccineDialog" max-width="800">
      <vaccineList @refreshData="refreshData" @justRefresh="justRefresh" :id="planId" :loginId="id_user_login"
        :perameters="details"></vaccineList>
    </v-dialog>
    </v-expansion-panel>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxPaging,
  DxScrolling,
  DxLookup,
} from "devextreme-vue/data-grid";
import DeleteTemplateDialog from "./DeleteTemplateDialog";
import vaccineList from "./VaccineListFromProducts.vue";
import Alerts from "../../../../components/Alerts.vue"
export default {
  name: "ManageVaccinePlans",
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DeleteTemplateDialog,
    DxScrolling,
    Alerts,
    DxLookup,
    vaccineList,
  },
  props: ["win"],
  data() {
    return {
      tab: null,
      VaccineList: [],
      showRowLines: true,
      showBorders: true,
      rowAlternationEnabled: true,
      ID_Vaccine: "",
      details: [],
      form: {},
      id_user_login: null,
      templateToDelete: null,
      deleteDialog: false,
      planComment: null,
      planId: null,
      editorOption: {
        placeholder: "Comentarios...!",
      },
      selectedPerameter: [],
      addVaccineDialog: false,
      detailData: {},
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      typebusiness:null,
      items: [
        {
          ID: "1",
          Name: "Dia",
          Value: "Day"
        },
        {
          ID: "2",
          Name: "Semana",
          Value: "Week"
        },
        {
          ID: "3",
          Name: "Mes",
          Value: "Month"
        },
        {
          ID: "4",
          Name: "Año",
          Value: "Year"
        },
      ],
      search: {},
    };
  },
  watch: {
    deleteDialog() {
      if (!this.deleteDialog) {
        this.closeDialog();
      }
    },
  },
  mounted() {
    this.typebusiness = JSON.parse(localStorage.getItem("user")).businesstype || ""
    this.id_user_login = JSON.parse(localStorage.getItem("user")).id;
    this.search.business = JSON.parse(localStorage.getItem("user")).businessid || ""
    this.search.branch = JSON.parse(localStorage.getItem("user")).branch || ""
    this.form.ID_Business = this.search.business
    this.form.ID_Branch = this.search.branch
    this.allVaccines();
  },
  methods: {
    allVaccines() {
      this.$API.vaccines.getVaccines(this.search).then((response) => {
        /*  console.log("estas son las vacunas", response); */
        this.VaccineList = response;
      });
    },
    closeAlert() {
      this.alert.show = false;

      /*  if (this.alert.options == "redirectcustomer") {
         this.$store.dispatch("deleteWindowByUuid", { uuid: this.win.uuid });
         this.$store.dispatch("addWindow", {
           name: "customerList",
           component: "CustomersList",
           unique: true,
         }); */
      /*      this.$store.dispatch("reloadData", "customers"); */

    },
    acceptAlert() {
      this.alert.show = false;

      /*  if (this.alert.options == "deletecustomer") {

       } */
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },

    async updateMedicationPlan() {
      this.$refs.observer.validate();
      const isValid = await this.$refs.observer.validate();
      let data = {
        PlanName: this.form.name,
        Comment: this.form.Comment,
        ID_User_Update: JSON.parse(localStorage.getItem("user")).id || "",
        ID_Business: this.form.ID_Business,
        ID_Branch: this.form.ID_Branch,
        ID_Vaccine_Plan: this.selectedPerameter.ID_Vaccine_Plan || ""
      };
      
       if (isValid) {
        if (!this.form.Comment) {
          this.$toasted.error("Comment Field is required.");
          return;
        }
        this.$API.vaccines.updateVaccinePlanHeader(data).then(
          () => {
            this.allVaccines();
            this.showAlert("success", "Actualización correcta", "Plan de vacunas actualizado con éxito");
            this.cleanForm();
            this.$refs.observer.reset();
          },
          (err) => {
            this.allVaccines();
            console.log(err);

            this.cleanForm();
            this.showAlert("danger", "Error", "Error al actualizar el plan de vacunas");
          }
        );
      }

      },
    showVaccineDetails(data) {
      this.planComment = data.Comment;
      this.planId = data.ID_Vaccine_Plan;
      data.name = data.PlanName;
      this.form = data;
      this.selectedPerameter = data;
      this.detailData = data;
      this.$API.vaccines
        .showVaccineDetails(data.ID_Vaccine_Plan)
        .then((response) => {
          this.details = response;
          this.ID_Vaccine = data.ID_Vaccine_Plan;
        });
    },
    UpdatePlansRow(e) {
      e.data.ID_User_Update = this.id_user_login;
      this.$API.vaccines
        .updateVaccinePlan(e.data.ID_Perameter, e.data)
        .then((response) => {
          console.log(response);
          /*  this.showAlert("success", "Éxito", "Registro Actualizado"); */
        });
    },
    DeletePlansRow(e) {
      this.$API.vaccines.deletePlanRow(e.data.ID_Perameter).then((response) => {
        console.log(response);
        /*         this.showAlert("success", "Éxito", "Registro eliminado"); */
      });
    },
    async addVaccine() {
      this.addIDsToForm()
      this.$refs.observer.validate();
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        if (!this.form.Comment) {
          this.$toasted.error("El comentario es requerido.");
          return;
        }
        this.form.ID_User_Insert = this.id_user_login;
        //add id branch and id business from local starage
        this.form.ID_Business = JSON.parse(localStorage.getItem("user")).businessid || ""
        this.form.ID_Branch = JSON.parse(localStorage.getItem("user")).branch || ""

        this.$API.vaccines.addVaccine(this.form).then((response) => {
          this.VaccineList = response;
          /* this.$toasted.success("El plan de vacunas ha sido creado"); */
          console.log(response);
          this.showAlert("success", "Éxito", "Plan creado con exito");
          this.allVaccines();
          this.planComment = null;
          this.planId = null;
          this.details = [];
          this.form = {};
          this.$refs.observer.reset();
        });
      }

    },
    async addVaccinevet() {
      this.addIDsToForm()
      this.$refs.observer.validate();
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        if (!this.form.Comment) {
          this.$toasted.error("El comentario es requerido.");
          return;
        }
        this.form.ID_User_Insert = this.id_user_login;
        this.form.ID_Business = JSON.parse(localStorage.getItem("user")).businessid || ""
        this.form.ID_Branch = JSON.parse(localStorage.getItem("user")).branch || ""
        this.$API.vaccines.addVaccinevet(this.form).then((response) => {
          this.VaccineList = response;
          /* this.$toasted.success("El plan de vacunas ha sido creado"); */
          console.log(response);
          this.showAlert("success", "Éxito", "Plan creado con exito");
          this.allVaccines();
          this.planComment = null;
          this.planId = null;
          this.details = [];
          this.form = {};
          this.$refs.observer.reset();
        });
      }

    },
    addIDsToForm() {
      this.search.business = JSON.parse(localStorage.getItem("user")).businessid || ""
      this.search.branch = JSON.parse(localStorage.getItem("user")).branch || ""
      this.form.ID_Business = this.search.business
      this.form.ID_Branch = this.search.branch

    },
    deleteVaccine(e) {
      this.$API.vaccines.deleteVaccine(e.ID_Vaccine_Plan).then((response) => {
        this.closeDialog();
        console.log(response);
        /*      this.showAlert("success", "Éxito", "Registro eliminado"); */
        this.planComment = null;
        this.planId = null;
      });
    },
    justRefresh() {
      this.showVaccineDetails(this.detailData);
      /* this.addVaccineDialog = false */
    },
    refreshData() {
      this.showVaccineDetails(this.detailData);
      this.addVaccineDialog = false
    },
    closeDialog() {
      this.templateToDelete = null;
      this.deleteDialog = false;
      this.detailData = {};
      this.allVaccines();
    },
    cleanForm() {
      this.form = {};
      this.selectedPerameter = {};
      this.details = [];
    },
  },
};
</script>

<style scoped>
.btn-add {
  background-color: #3498DB !important;
  color: white;
}

.btnsave {
  background-color: #00A178 !important;
  color: white;
}

.btnclean {
  background-color: #F29D35 !important;
  color: white;
}

.btndelete {
  background-color: #E3191E !important;
  color: white;
}

.box {
  border-style: solid;
  border-color: black;
  border-width: 1px;
  background-color: white;
  padding: .5rem;
  border-radius: 15px;
  margin: 0.5rem;

}

i.dx-icon.dx-icon-edit-button-addrow {
  padding-bottom: 6px !important;
}

.disabledbutton {
  pointer-events: none;
  opacity: 0.4;
}

.ql-container.ql-snow {
  min-height: 100px !important;
}
</style>
